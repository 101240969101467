





















import { Component, Vue } from 'vue-property-decorator';
import ConfigurazioneLiturgiaDataService from "../services/ConfigurazioneLiturgiaDataService";
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';
import ConfiguratoreLiturgiaCanzoneRow from '../model/ConfiguratoreLiturgiaCanzoneRow';
import { Utils } from "../utils/utils";
import CanzoneModel from '../model/CanzoneModel';
import ElencoTestoConAccordiCanzoni from './ElencoTestoConAccordiCanzoni.vue';

@Component({
    components: {
        ElencoTestoConAccordiCanzoni,
    }
})

export default class ElencoTestoConAccordiCanzoniConfigurazioneLiturgica extends Vue {
    // Initial data can be declared as instance properties
    pageTitle: string = 'Canzoni Liturgia';

    private currentConfigurazione: any = null;
    private canzoniSelectedListGroupByParteDellaMessa: any = [];

    getConfigurazioneLiturgia(id: string) {
        ConfigurazioneLiturgiaDataService.get(id)
            .then((response) => {
                //console.log(response);
                this.currentConfigurazione = response.data;
                var configuratoreLiturgiaCanzoneRowList: ConfiguratoreLiturgiaCanzoneRow[] = [];
                if (this.currentConfigurazione != null && this.currentConfigurazione.configurazioneLiturgiaCanzoni.length > 0) {
                    for (let item of this.currentConfigurazione.configurazioneLiturgiaCanzoni) {
                        var configuratoreLiturgiaRow = new ConfiguratoreLiturgiaCanzoneRow(new CanzoneModel(item.canzone.titolo, item.canzone.id, item.canzone.idUtenteCreazione, item.canzone.autore, item.canzone.testo, item.partiDellaMessa, item.tempLiturgici, item.paroleChiave, item.canzone.vivibilityPolicy, item.canzone.allegati), new MultipleMultiselectItem(item.parteDellaMessa.nome, item.parteDellaMessa.id, item.parteDellaMessa.ordinamento));
                        configuratoreLiturgiaRow.numeroParoleTrovate = item.paroleTrovate.length;
                        configuratoreLiturgiaRow.paroleTrovate = item.paroleTrovate;
                        configuratoreLiturgiaRow.selected = true;
                        //console.log(configuratoreLiturgiaRow);
                        configuratoreLiturgiaCanzoneRowList.push(configuratoreLiturgiaRow);
                    }
                }
                this.canzoniSelectedListGroupByParteDellaMessa = Utils.groupBy(configuratoreLiturgiaCanzoneRowList.sort(function (a: any, b: any) {
                    return a.parteDellaMessaOrdinamento - b.parteDellaMessaOrdinamento;
                }), 'parteDellaMessaNome');
            })
            .catch((e) => {
                console.log(e);
            });
    }

    mounted() {
        this.getConfigurazioneLiturgia(this.$route.params.id);
    }
}
